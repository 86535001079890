

import CardCopy from './../cardCopy/cardCopy.js';
import './errorPage.css';
import VectorError from './../../images/VectorError.png';
import VectorWhats from './../../images/VectorWhats.png';
import VectorBlock from './../../images/VectorBlock.png';
import Logo from './../../images/logo.png';
import Content from './../content/content.js';

export default function ErroPage(){

    const openWhats = () => {
        window.open('https://wa.me/554830255122', '_blank');
    };
    return(
        <div className="errorPage base row g-0 ">
            <div className="card-absolute card-logo row g-0 ">
                <img src={Logo} className="logo"/>
            </div>
            <Content className="col-xs-12 col-lg-8">
                <div className="div-content">
                    <img src={VectorBlock}/>
                    <br/>
                    <span>Ops! Pareceu que o seu código Pix não foi gerado com sucesso, pedimos desculpas.</span>
                    <br/>
                    <span>Clique abaixo e converse com nossos consultores para receber um novo código Pix.</span>
                </div>
            </Content>
            <div className="card-absolute row g-0 col-xs-12 col-lg-8">
                <b className="title-card">Olá, parece que houve um erro.</b>
                <CardCopy code={'Erro: código Pix inexistente'} vector={VectorError} func={()=>{}}/>
                <button onClick={openWhats} className="col-12 button-whats">
                    <img src={VectorWhats}/>
                    Peça ajuda aos consultores</button>
            </div>
        </div>)
}