import './content.css';

function Content({children, className=''}){
    return(<div className={`content ${className}`}>
                <div className="container">
                    {children}
                </div>
           </div>)

}
export default Content;