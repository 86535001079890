import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import App from "./App";

const isProd = window.location.hostname.includes("manager");
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <BrowserRouter basename="">
        <Routes>
            <Route path="/" element={<App startError={true}/>} />
            <Route path="/:token" element={<App />} />
        </Routes>
    </BrowserRouter>
);
