import CardCopy from './../cardCopy/cardCopy.js';
import './startPage.css';
import VectorCopy from './../../images/VectorCopy.png';
import VectorInf from './../../images/VectorInf.png';
import VectorTicket from './../../images/VectorTicket.png';
import Logo from './../../images/logo.png';
import Content from './../content/content.js';
import QRCode from 'qrcode.react';

export default function StartPage({data, setFinish}){

    const handleCopy = (code) => {
        if (window.isSecureContext && navigator.clipboard) {
            navigator.clipboard.writeText(code);
        } else {
            unsecuredCopyToClipboard(code);
        }
        setFinish(true)
    };
    const unsecuredCopyToClipboard = (text) => {
            const textArea = document.createElement("textarea");
            textArea.value=text;
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            try{
                document.execCommand('copy');
            }catch(err){
                console.error('Unable to copy to clipboard',err)}
            document.body.removeChild(textArea)
    }
/**
 * Copies the text passed as param to the system clipboard
 * Check if using HTTPS and navigator.clipboard is available
 * Then uses standard clipboard API, otherwise uses fallback
*/

    return(
        <div className="startPage base row g-0 ">
            <div className="card-absolute card-logo row g-0 ">
                <img src={Logo} className="logo"/>
            </div>
            <Content className="col-xs-12 col-lg-8">
                <div className="row g-0">
                    <div className="col-xs-12 col-lg-9">
                        <b>Cobrança de: GnTech Laboratório</b><br/>
                        <span>CNPJ: 14.304.432/0001-09</span>
                        <hr/>
                        {data.products?.map((p) =>
                            (<span>{p}</span>)
                        )}

                        <hr/>
                        <div className="d-flex line-content">
                            <img className="icon" src={VectorTicket}/>
                            <span>Valor total (1 parcela):</span>
                            <b>R$ {data.price}</b>
                        </div>
                        <div className="d-flex line-content">
                            <img className="icon" src={VectorInf}/>
                            <span>Código válido até: </span>
                            <b>5 dias úteis</b>
                        </div>
                    </div>
                    <div className="div-qrcode col-xs-12 col-lg-3">
                        <QRCode value={data.code} size={145} />
                    </div>
                </div>
            </Content>
            <div className="card-absolute row g-0 col-xs-12 col-lg-8">
                <b className="title-card">Olá {data.name}</b>
                <span className="legend-card">
                    Faça seu PIX pelo QR Code acima ou, se preferir, copie e cole o código abaixo:
                </span>
                <CardCopy code={data.code} vector={VectorCopy} func={() => handleCopy(data.code)}/>
                <button onClick={() => handleCopy(data.code)} className="col-12">Clique e copie o código Pix</button>
            </div>
        </div>)
}