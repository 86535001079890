

import CardCopy from './../cardCopy/cardCopy.js';
import './finishPage.css';
import VectorCheck from './../../images/VectorCheck.png';
import Logo from './../../images/logo.png';
import Content from './../content/content.js';

export default function FinishPage({data}){

    const handleCopy = () => {
         navigator.clipboard.writeText(data.code);
    };
    return(
        <div className="finishPage base row g-0 ">
            <div className="card-absolute card-logo row g-0 ">
                <img src={Logo} className="logo"/>
            </div>
            <Content className="col-xs-12 col-lg-8">
                <b className="card-title">Perfeito! Agora siga as intruções:</b>
                <hr/>
                <ol>
                    <li>Abra o <b>aplicativo do seu banco</b> de preferência para pagamentos.</li>
                </ol>
                <hr/>
                <ol  start="2">
                    <li>Selecione a aba “Pix” e clique na opção <b>“Pix copia e cola”</b>.</li>
                </ol>
                <hr/>
                <ol  start="3">
                    <li>Cole o <b>código copiado</b> e finalize o pagamento.</li>
                </ol>
            </Content>
            <div className="card-absolute row g-0  col-xs-12 col-lg-8">
                <CardCopy code={data.code} vector={VectorCheck} func={handleCopy}/>
                <button onClick={handleCopy} className="col-12">Pix copiado com sucesso!</button>
            </div>
        </div>)
}