import './cardCopy.css';

function CardCopy({code, vector, func}){


    return(<div className="cardCopy col-12" onClick={func}>
                <div className="div-card">
                    <span className="title-card">Pix copia e cola</span>
                    <span className="code-card">{code}</span>
                </div>
                <div className="img-card">
                    <img src={vector}/>
                </div>
            </div>)
}
export default CardCopy;