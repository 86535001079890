const baseRoutes = {
    finish:'/finish',
    error: '/error'
};
const homologRoutes = {
    //'backend':'http://192.168.25.106:8087/'
    'backend':'http://localhost:8088/'//TODO ARRUMAR
}

const prodRoutes = {
    'backend':'https://rede.gntech.med.br:8443/' //TODO ARRUMAR
}

const isProd = window.location.hostname.includes('rede.gntech.med.br');

const AppRoutes = {...baseRoutes, ...(isProd?prodRoutes:homologRoutes)}
export default AppRoutes;
