import "./styles.css";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import AppRoutes from "./routes.js";
import QRCode from 'qrcode.react';
import StartPage from './components/startPage/startPage.js';
import FinishPage from './components/finishPage/finishPage.js';
import ErrorPage from './components/errorPage/errorPage.js';
import Load from './components/load/load.js';

export default function App({startError = false}) {
    const { token } = useParams();
    const [data,setData] = useState({});
    const [error, setError] = useState(startError);
    const [load, setLoad] = useState(true);
    const [finish, setFinish] = useState(false);
    async function getData() {
        try {
            const header = {Authorization: `Basic ${token}`}
            const response = await axios.get(
              `${AppRoutes.backend}get_data`,
                {headers: header}
            );
            console.log(response.data);
            if(!response.data || !response.data.code){
                setError(true);
            }
            setData(response.data);
            setLoad(false);
        } catch (error) {
            console.error(error);
            setError(true);
            setLoad(false);
        }
    }
    const handleCopy = (text) => {
         navigator.clipboard.writeText(text)
    };
  useEffect(() => {
    console.log(startError)
    if (!startError){
        getData();
    }
    else{
        setLoad(false);
    }
  }, []);
  return (
    <div className="App w-100">
        <div className={`loadPage ${load ? '' : 'hide'}`}>
                <div className="content">
                    <Load message={false}/>
                    <p>Carregando o seu código Pix</p>
                </div>
        </div>
        {error?
            (<ErrorPage />):
            finish?
                (<FinishPage data={data}/>):
                (<StartPage data={data} setFinish={setFinish}/>)

        }
    </div>
  );
}
