import React from "react";
import "./load.css";

function Load({message, qtd = 10}){
    return(
        <div className="divLoad wrapper">
          <div className="loader">
            <div className="wave top-wave">
                {[...Array(qtd).keys()].map((i) => (
                    <div key={`dot_top_${i}`} className="dot" style={{animationDelay: `${-0.9+(0.10*i)}s`}}></div>)
                )}
            </div>
            <div className="wave bottom-wave">
                {[...Array(qtd).keys()].map((i) => (
                    <div key={`dot_bottom_${i}`} className="dot" style={{animationDelay: `${-0.15+(0.10*i)}s`}}></div>)
                )}
            </div>
          </div>
          {message?(<p className="loadText">{message}</p>):(<></>)}
        </div>)
}
export default Load;